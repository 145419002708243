import { CSSVariablesResolver } from '@mantine/core';

export const resolver: CSSVariablesResolver = () => ({
  variables: {
    '--button-bg': 'var(--quo-blue-75)',
    '--button-hover': 'var(--quo-blue-80)',
    '--mantine-color-error': 'var(--quo-power-75)',
  },
  light: {
    '--mantine-color-body': 'var(--mercury-bg)',
    '--mantine-color-text': 'var(--quo-char)',
  },
  dark: {
    '--mantine-color-body': 'var(--mercury-bg)',
    '--mantine-color-text': 'var(--quo-char-25)',
  },
});
