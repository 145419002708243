interface TreeNode<T> {
  value: number;
  parent_id: number | null;
  depth: number;
  children?: Array<TreeNode<T>>;
}

interface FlatNode {
  value: number;
  parent_id: number | null;
}

export function flatArrayToTree<T extends FlatNode>(flatArray: Array<T>): Array<TreeNode<T>> {
  const map: Record<number, TreeNode<T>> = {};

  // Step 1: Create a map of all nodes
  for (let i = 0; i < flatArray.length; i += 1) {
    const item = flatArray[i];
    map[item.value] = {
      ...item,
      children: [],
      depth: 0, // initialize depth
    };
  }

  const tree: TreeNode<T>[] = [];

  // Step 2: Build the tree structure
  for (let i = 0; i < flatArray.length; i += 1) {
    const item = flatArray[i];
    const node = map[item.value];

    if (item.parent_id === null) {
      tree.push(node);
    } else {
      const parent = map[item.parent_id];

      if (parent && parent.children) {
        parent.children.push(node);
      }
    }
  }

  // Step 3: Recursively set the depth of each node
  function setDepth<T>(node: TreeNode<T>, depth: number) {
    map[node.value].depth = depth;
    const children = node.children || [];
    children.forEach((child) => setDepth(child, depth + 1));
  }

  tree.forEach((rootNode) => setDepth(rootNode, 0));

  return tree;
}
