import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PropsWithChildren, useState } from 'react';
import { QueryClientProvider, UseQueryOptions } from '@tanstack/react-query';
import { MantineProvider } from '@mantine/core';

// Context
import { MercuryPagesProvider } from './context/MercuryPagesContext';
import { MercuryPermissionsProvider } from './context/MercuryPermissions';
import { ToastContextProvider } from './context/ToastContext';
import { ToastContainer } from './components/content/toast/ToastContainer';
import { ConfirmationProvider } from './components/content/confirmationdialog/ConfirmationContext';
import { SessionContextProvider } from './context/SessionContext';
import { ThemeProvider as MercuryThemeProvider } from './context/ThemeContext';
import { DrawerProvider } from './context/DrawerContext';
import Priority from './components/Priority';
import RateLimit from './client/features/ratelimit/RateLimit';

import { queryClient as qc } from './lib/react-query/client';
import { PermanentMessageProvider } from './context/PermanentMessageContext';
import { PermanentMessage } from './components/content/permanentmessage/PermanentMessage';
import { mantineTheme } from './theme';
import { resolver } from './theme/resolver';
import { colorSchemeManager } from './theme/colorScheme';

import type { UserId } from './types/api';

const App = ({
  children,
  prefetchQueries = [],
}: PropsWithChildren<{ prefetchQueries?: Array<(userId: UserId) => Omit<UseQueryOptions<any>, 'select'>> }>) => {
  const [queryClient] = useState(qc());

  return (
    <QueryClientProvider client={queryClient}>
      <MantineProvider
        theme={mantineTheme}
        cssVariablesResolver={resolver}
        colorSchemeManager={colorSchemeManager}
        defaultColorScheme="auto"
      >
        <MercuryThemeProvider>
          <MercuryPagesProvider>
            <MercuryPermissionsProvider>
              <ConfirmationProvider>
                <ToastContextProvider>
                  <DrawerProvider>
                    <PermanentMessageProvider>
                      <SessionContextProvider>
                        {children}
                        <ToastContainer />
                        <PermanentMessage />
                        <RateLimit />
                        <ReactQueryDevtools initialIsOpen={false} />
                        <Priority queries={prefetchQueries} />
                      </SessionContextProvider>
                    </PermanentMessageProvider>
                  </DrawerProvider>
                </ToastContextProvider>
              </ConfirmationProvider>
            </MercuryPermissionsProvider>
          </MercuryPagesProvider>
        </MercuryThemeProvider>
      </MantineProvider>
    </QueryClientProvider>
  );
};

export default App;
