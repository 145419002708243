import type { ReactNode } from 'react';
import { Modal, type ModalProps } from '@mantine/core';

import classes from './Modal.module.css';

interface MercuryModalProps extends ModalProps {
  alternativeBackground?: boolean;
  allowOverflow?: boolean;
  link?: ReactNode;
}

const MercuryModal = ({
  children,
  alternativeBackground,
  allowOverflow,
  link,
  withCloseButton = true,
  ...modalProps
}: MercuryModalProps) => (
  <Modal.Root
    {...modalProps}
    classNames={{
      title: classes.title,
      header: `${alternativeBackground ? classes.alternativeBg : ''} ${link ? classes.headerWithLink : ''}`,
      content: `${alternativeBackground ? classes.alternativeBg : ''} ${allowOverflow ? classes.allowOverflow : ''}`,
      close: classes.close,
    }}
  >
    <Modal.Overlay {...modalProps.overlayProps} />
    <Modal.Content>
      {(modalProps.title || withCloseButton) && (
        <Modal.Header>
          {modalProps.title && (
            <div className={`${link ? classes.titleWrapper : ''}`}>
              <Modal.Title>{modalProps.title}</Modal.Title>
              {link && link}
            </div>
          )}

          {withCloseButton && <Modal.CloseButton />}
        </Modal.Header>
      )}
      <Modal.Body>
        {children}
      </Modal.Body>
    </Modal.Content>
  </Modal.Root>

);

export default MercuryModal;
