import { useCombobox } from '@mantine/core';

import { useItemStore } from './useItemsStore';
import type { Item, MultiSelectBaseProps, Value } from './types';

export const useMultiSelect = ({
  items,
  setValue,
  value,
  removeSelectedItems = false,
}: MultiSelectBaseProps) => {
  const itemsStore = useItemStore({ items, value, removeSelectedItems });

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  });

  const isSelected = (lookFor: Value) => (value ? value.some((val) => val.value === lookFor) : false);

  const isDisabled = (lookFor: Value): boolean => {
    const item = itemsStore.itemsStore.listStore.byValue[lookFor];

    if (item.root !== null && item.parent !== null) {
      return value.some((val) => val.value === item.root || val.value === item.parent) || isDisabled(item.parent);
    }

    return false;
  };

  const filterSelected = (item: Item) => value.filter((val) => val.value !== item.value);

  const handleValueRemove = (val: Value) => {
    const item = itemsStore.getItemByValue(val);

    setValue(filterSelected(item));
  };

  const getNewSelectedItems = (item: Item) => {
    let selected = value;

    // Deselect item if its already selected
    if (isSelected(item.value)) {
      return filterSelected(item);
    }

    // If its root remove all children
    if (item.root === null) {
      selected = value.filter((val) => itemsStore.getItemByValue(val.value).root !== item.value);
    }

    // If its a parent remove all children
    if (item.parent !== null) {
      selected = value.filter((val) => itemsStore.getItemByValue(val.value).parent !== item.value);
    }

    return [...selected, item];
  };

  const handleValueSelect = (val: Value) => {
    combobox.closeDropdown();

    const item = itemsStore.getItemByValue(val);

    const newSelectedItems = getNewSelectedItems(item);

    setValue(newSelectedItems);

    itemsStore.setSearch('');
  };

  return {
    combobox,
    handleValueSelect,
    handleValueRemove,
    isSelected,
    isDisabled,
    ...itemsStore,
  };
};
