/* eslint-disable react/no-array-index-key */
import type { ReactNode } from 'react';
import { Flex, type FlexProps } from '@mantine/core';

import classes from './List.module.css';

interface ListProps<T> extends FlexProps {
  items: T[];
  renderItem: (item: T) => ReactNode;
  id?: string;
}

const List = <T extends {} | string>({ items, renderItem, id, direction = 'column', ...flexProps }: ListProps<T>) => (
  <Flex component="ul" classNames={{ root: classes.list }} direction={direction} {...flexProps}>
    {items.map((item, i) => (
      <li key={`list-${id ? `-${id}-` : ''}${i}`}>{renderItem(item)}</li>
    ))}
  </Flex>
);

export default List;
