// Styles & Assets
import { Flex } from '@mantine/core';

import { useTheme } from '@/hooks/useTheme';

import Moon from '@/assets/icons/content/moon.svg';

const ThemeSwitch = () => {
  const { theme, changeTheme } = useTheme();
  const themeToSwitch = theme === 'light' ? 'dark' : 'light';

  const toggleTheme = () => {
    changeTheme(themeToSwitch);
  };

  return (
    <Flex
      component="button"
      type="button"
      aria-label={`Switch to ${themeToSwitch} mode`}
      onClick={toggleTheme}
      id="theme-switch"
    >
      <Moon />
    </Flex>
  );
};

export default ThemeSwitch;
