import {
  FunctionComponent, PropsWithChildren, SVGProps, useEffect, useState,
} from 'react';
import { Flex } from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';
import { useLocation } from 'react-router-dom';

// Assets
import classes from './Topbar.module.css';
import ChevronDown from '@/assets/icons/content/chevron-down.svg';

interface DropdownProps {
  icon: FunctionComponent<SVGProps<SVGSVGElement>> | any;
  hideArrow?: boolean;
  size?: number;
}

const DropdownMenu = ({
  children,
  icon: Icon,
  hideArrow = false,
  size = 180,
}: PropsWithChildren<DropdownProps>) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded((state) => !state);
  };

  const collapse = () => setExpanded(false);

  const clickRef = useClickOutside(collapse);
  useEffect(collapse, [useLocation()]);

  return (
    <div className={classes.dropdown} ref={clickRef}>
      <Flex
        component="button"
        type="button"
        className={`${classes.iconWrapper} ${expanded ? classes.expanded : ''}`}
        onClick={toggleExpanded}
        gap={0}
      >
        <Icon />
        {!hideArrow && <ChevronDown />}
      </Flex>

      <ul
        className={`${classes.dropdownMenu} ${expanded ? classes.expanded : ''}`}
        style={{
          ['--dropdown-menu-size' as string]: `${size}px`,
        }}
      >
        {children}
      </ul>
    </div>
  );
};

export default DropdownMenu;
