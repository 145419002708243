import type { ChangeEvent } from 'react';
import { Checkbox, Input, Stack } from '@mantine/core';

import type { FilterProps } from '../types';
import type { Single } from '@/types/meta';

const SingleFilter = ({ setFilter, filter, filterMeta }: FilterProps<Single, string>) => {
  const selectItem = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    setFilter({ [filterMeta.name]: checked || undefined });
  };

  return (
    <Input component={Stack} justify="center">
      <Checkbox
        onChange={selectItem}
        value={filter}
        name={filterMeta.name}
        checked={filter === 'true'}
        label={filterMeta.label}
      />
    </Input>
  );
};

export default SingleFilter;
