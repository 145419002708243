import { type ReactNode, useCallback } from 'react';
import { Group } from '@mantine/core';
import { useMemoizedFn } from 'ahooks';

import DateRange from '@/components/content/filters/components/DateRange';
import Single from '@/components/content/filters/components/Single';
import MultiSelect from '@/components/content/filters/components/MultiSelect';
import Tooltip from '@/components/content/tooltip/Tooltip';
import ConditionalWrapper from '@/components/content/conditionalwrapper/ConditionalWrapper';

import { Cardinalities, Single as TSingle, Multiple, TDate } from '@/types/meta';

import classes from './Meta.module.css';
import { MetaStore } from '@/hooks/useMetaStore';

interface ComponentProps {
  meta: TDate | Multiple | TSingle;
  store: MetaStore;
}

const FiltersComps: Record<
Cardinalities.range | Cardinalities.multiple | Cardinalities.single,
  typeof DateRange | typeof MultiSelect | typeof Single
> = {
  [Cardinalities.range]: DateRange,
  [Cardinalities.multiple]: MultiSelect,
  [Cardinalities.single]: Single,
};

const MetaComponent = ({ meta, store }: ComponentProps) => {
  const hasDefaultChoice = !!meta.default_choice;
  const Component = FiltersComps[meta.cardinality];

  const onClearFilter = useMemoizedFn(() => {
    const mStore = meta.non_meta ? store.customMeta : store.filters;

    if (hasDefaultChoice) {
      mStore.reset(meta.name);
      return;
    }

    mStore.clear(meta.name);
  });

  const onDispatch = useMemoizedFn((filter: Record<string, any>) => {
    const mStore = meta.non_meta ? store.customMeta : store.filters;
    mStore.set(filter);
  });

  const wrapperTooltip = useCallback((children: ReactNode) => (
    <Tooltip title={`This filter will reset back to the default value: ${meta.default_choice}`}>
      {children}
    </Tooltip>
  ), []);

  if (meta.cardinality === Cardinalities.multiple && !meta.choices) {
    return null;
  }

  return (
    <div>
      <Group justify="space-between">
        <h3 className={classes.metaTitle}>
          {meta.label}
        </h3>
        {meta.cardinality !== 'single' && (
          <ConditionalWrapper condition={hasDefaultChoice} wrapper={wrapperTooltip}>
            <button type="button" onClick={onClearFilter} className={classes.clear}>
              {meta.default_choice ? 'Reset' : 'Clear'}
            </button>
          </ConditionalWrapper>
        )}
      </Group>

      <div className={classes.metaComponentWrapper}>
        <Component
          filterMeta={meta as any}
          filter={store.filters.state[meta.name] || store.customMeta.state[meta.name] || null}
          clearFilter={onClearFilter}
          setFilter={onDispatch}
        />
      </div>
    </div>
  );
};

export default MetaComponent;
